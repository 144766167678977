@import "~antd/dist/antd.css";
.app-root {
  width: 100%;
  height: 100%;
}

.app-container {
  width: 100%;
  height: 100%;
}
.main-layout .logo {
  height: 32px;
  margin: 16px;
  padding: 6px;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  font-size: 21px;
  font-weight: bold;
  text-align: left;
  line-height: 20px;
}
.main-content {
  padding: 10px
}
.iframe-drawer .ant-drawer-body {
  padding: 0;
  margin: 0;
}

.gecko-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.table-actions {
  padding: 20px 0;
}

.show-processed-only {
  cursor: pointer;
  margin-left: 4px;
}

.title-separator:before {
  display: none;
}

.title-separator .ant-divider-inner-text {
  padding-left: 0;
}

.audio-file-table .ant-pagination-item,
.audio-file-table .ant-pagination-jump-next,
.audio-file-table .ant-pagination-jump-prev,
.audio-file-table .ant-pagination-options-size-changer {
    display: none;
}

.gecko-iframe {
  margin: 0;
  padding: 0;
  min-width: 100%;
  height: 100%;
}
